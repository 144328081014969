<template>
  <div class="zch-legal-agreement">
    <Navigation />
    <div class="zch-legal-agreement-container">
      <div class="zch-legal-agreement-heading">
        <img alt="ZAKchat" src="../assets/logo.svg" />
        <div class="zch-legal-agreement-heading-effective-date">
          Effective date: January 1, 2020
        </div>
        <div class="zch-legal-agreement-heading-notice">
          ZAKchat Legal Agreement
        </div>
      </div>
      <div class="zch-legal-agreement-regulations">
        <div class="zch-legal-agreement-regulations-item-heading">
          ZAKchat’ Terms and Conditions for Charities
        </div>
        <div class="zch-legal-agreement-regulations-item-text">
          Shamaazi Ltd, a private limited company registered in England and
          Wales under company number 10622013 and have our registered office at
          36 Alie Street, London, United Kingdom, E1 8DA, operates and maintains
          the online donations platform at www.ZAKchat.com, with ZAKchat as one
          of its trading names.<br /><br />
          ZAKchat is a website at www.ZAKchat.com which allows charities to run
          fundraising campaigns, and users to donate to charities of their
          choice, (together, the "ZAKchat Services"). The Terms and Conditions
          govern the Charity's use of the ZAKchat Services. Please read these
          Terms and Conditions carefully. By applying to be (and continuing to
          be) a member of the ZAKchat platform, each charity and similar
          organisation (each a “Charity” for the purposes of these Terms and
          Conditions) is agreeing to these Terms and Conditions. If the Charity
          does not wish to be bound by these Terms and Conditions, the Charity
          should not continue to use or access any of the ZAKchat Services (as
          defined below).<br /><br />
          ZAKchat operates its business in a number of countries and Sections 1
          to 15 of these Terms and Conditions (the “core terms”) apply to all
          Charities, wherever they are located. ZAKchat may change or update
          these Terms and Conditions from time to time. The current version of
          the Terms and Conditions will be available on www.ZAKchat.com. It is
          the Charity's responsibility to access and check these Terms and
          Conditions whenever the Charity accesses or uses the ZAKchat Services.
          The latest version of these Terms and Conditions will govern any
          future usage by the Charity of the ZAKchat Services.<br /><br />
          These Terms and Conditions supersede and replace all previous versions
          of the Terms and Conditions.
        </div>
        <div class="zch-legal-agreement-regulations-item-heading">
          1. Term &amp; Membership
        </div>
        <div class="zch-legal-agreement-regulations-item-text">
          1.1 For the purposes of these Terms and Conditions, "Commencement
          Date" shall mean the date from which the Charity is notified that the
          Charity has been successfully registered to use the ZAKchat Services
          (which shall be at ZAKchat’s sole discretion). ZAKchat shall provide
          the ZAKchat Services to the Charity and the Charity shall comply with
          these Terms and Conditions until the membership is validly
          terminated.<br /><br />
          1.2 Membership is for a minimum period of one (1) year from the
          Commencement Date and thereafter from year to year until terminated by
          written notice by either party to the other, in accordance with
          Section 10.1 below. Please see Section 10 of these Terms and
          Conditions for further termination provisions.
        </div>
        <div class="zch-legal-agreement-regulations-item-heading">
          2. ZAKchat' Obligations
        </div>
        <div class="zch-legal-agreement-regulations-item-subheading">
          Donations
        </div>
        <div class="zch-legal-agreement-regulations-item-text">
          2.1 ZAKchat will facilitate all donations through Stripe Connect. This
          means that ZAKchat is entirely removed from the money flow, and
          donations are made from the donor directly into the Charity’s Stripe
          holding account.<br /><br />
          2.2 ZAKchat will operate the ZAKchat Services so that donors can make
          online donations by credit or debit card, or by any other payment
          method made available by ZAKchat, through a Fundraising Page set up
          for the Charity or on the Charity's area on the ZAKchat website. For
          all Charities, use of the ZAKchat Services is on an "as is" and "as
          available" basis. Whilst ZAKchat cannot promise troublefree use,
          experience suggests that the ZAKchat Services are reliable.
        </div>
        <div class="zch-legal-agreement-regulations-item-subheading">
          Support and Donation Acknowledgements
        </div>
        <div class="zch-legal-agreement-regulations-item-text">
          2.4 ZAKchat will send prompt email acknowledgements to donors in
          respect of each donation made to the Charity via the ZAKchat
          Services.<br /><br />
          2.5 ZAKchat will respond to donors' account enquiries that it
          receives.
        </div>
        <div class="zch-legal-agreement-regulations-item-subheading">
          Personal Information
        </div>
        <div class="zch-legal-agreement-regulations-item-text">
          2.6 ZAKchat will capture personal information including but not
          limited to the donor's name, address, mobile number and email address
          ("Personal Information") at all times in accordance with local privacy
          laws, regulatory requirements and guidance as updated and amended from
          time to time including such laws, regulatory requirements and guidance
          applicable in the territory where donors access the Website ("Local
          Privacy Laws") and protect and secure such information. Charities
          should refer to <br /><br />
          ZAKchat <RouterLink :to="'/privacy'">Privacy Policy</RouterLink> for
          more details of how ZAKchat processes Personal Information and uses
          cookies. For the avoidance of doubt this Section 2.7 does not imply
          that ZAKchat will share such information with the Charity, but
          selected Personal Information shall be shared with the Charity in
          accordance with Section 2.9 below.<br /><br />
          2.7 To the maximum extent possible under applicable law, ZAKchat will
          abide by the instructions of all donors in respect of their Personal
          Information.<br /><br />
          2.8 ZAKchat will not sell, trade or rent Personal Information to third
          parties.
        </div>
        <div class="zch-legal-agreement-regulations-item-subheading">
          Records and Audit Requirements
        </div>
        <div class="zch-legal-agreement-regulations-item-text">
          2.9 ZAKchat will keep proper records of all donations and income
          received and expenses incurred for such period as may from time to
          time be required by the applicable local governmental revenue body,
          and maintain records which relate to the Charity and the performance
          of the obligations hereunder (to which the Charity may, subject to the
          Global Privacy Laws, have access and make copies of at its own expense
          on request and at reasonable times).<br /><br />
          2.10 ZAKchat will provide each Charity with reports outlining: the
          number of donations received, the value of the donations received, and
          the Transaction Fees deducted from the total donations in accordance
          with Section 6.
        </div>
        <div class="zch-legal-agreement-regulations-item-subheading">
          Site Maintenance and Miscellaneous
        </div>
        <div class="zch-legal-agreement-regulations-item-text">
          2.11 ZAKchat will comply at all times with all applicable laws and
          applicable authority, licence, permit or registration requirements.<br /><br />
          2.12 ZAKchat will undertake planned maintenance and upkeep of the
          ZAKchat Services.
        </div>
        <div class="zch-legal-agreement-regulations-item-heading">
          3. Charity’s Obligations - General
        </div>
        <div class="zch-legal-agreement-regulations-item-subheading">
          Donations
        </div>
        <div class="zch-legal-agreement-regulations-item-text">
          3.1 The Charity will ensure that all content (including text and
          images) supplied by it to ZAKchat for display on the ZAKchat Services
          (including, in each case, links to any third party sites or content)
          will not violate any law or regulation or be defamatory, misleading,
          obscene or breach any intellectual property rights of a third party or
          breach any right of or duty owed to a third party.<br /><br />
          3.2 The Charity will provide written confirmation to ZAKchat of any
          donations (including any Gift Aid reclaimed on the same) that it
          permits ZAKchat to reimburse to the donor. ZAKchat will not permit
          refunds without prior written consent from the Charity, unless
          required by applicable local laws.<br /><br />
          3.3 Subject to local applicable laws, the Charity will permit ZAKchat
          to deduct such refunds, and any chargebacks arising, from subsequent
          donations payable to the Charity. ZAKchat reserves the right to
          invoice the Charity for any refunds and chargebacks issued with or
          without consent from the Charity, and the Charity agrees to settle
          that invoice within one (1) month of the invoice date.<br /><br />
          3.4 The Charity will maintain the necessary authority, permit,
          licence, consent, approval and registration for it to fundraise (and,
          where applicable, for ZAKchat to fundraise on its behalf) in
          accordance with applicable local laws and if ZAKchat needs any such
          authority, permit, licence, consent, approval or registration for it
          to fundraise on behalf of the Charity then the Charity will, at no
          cost to ZAKchat, provide all such assistance as ZAKchat reasonably
          requires to assist ZAKchat with the same.<br /><br />
          3.5 The Charity will inform ZAKchat immediately if, for any reason,
          it:<br /><br />
          <div class="zch-legal-agreement-regulations-item-text-sub">
            3.5.1 ceases to carry on operating for charitable purposes;<br /><br />
            3.5.2 is found to be engaged in any corrupt or immoral practices;<br /><br />
            3.5.3 is subject to any investigation or inquiry which could result
            in the necessary authority, permit, licence, consent, approval
            and/or registration for it to operate as a charity in accordance
            with applicable local laws being suspended, revoked or withdrawn; or
            3.5.4 ceases to have the necessary authority, permit, licence,
            consent, approval and/or registration for it to operate as a charity
            in accordance with applicable local laws.<br /><br />
            3.5.5 operates in any region sanctioned by either the UK or USA
            governments.<br /><br />
          </div>
          3.6 The Charity will promptly provide any information reasonably
          requested by ZAKchat in order for ZAKchat to be able to provide the
          ZAKchat Services to the Charity effectively.<br /><br />
          3.7 The Charity will ensure that, in each case where a donor is
          advised that his/her donation will be used for a specific purpose,
          appeal or outcome, the donation is used only for that specific
          purpose, appeal or outcome.<br /><br />
          3.8 The Charity will comply with all applicable laws and regulations
          in its use of the ZAKchat Services.<br /><br />
          3.9 The Charity represents, warrants and undertakes to ZAKchat
          that:<br /><br />
          <div class="zch-legal-agreement-regulations-item-text-sub">
            3.9.1 these Terms and Conditions have been accepted and agreed to by
            a sufficient number of its duly authorised representatives; and<br /><br />
            3.9.2 any documents provided to ZAKchat as part of the Charity’s
            application for registration or maintenance of such registration)
            have been or shall be executed by a sufficient number of its duly
            authorised representatives.<br /><br />
          </div>
        </div>
        <div class="zch-legal-agreement-regulations-item-heading">
          4. Charity’s Obligations – Data Protection and Direct Marketing
        </div>
        <div class="zch-legal-agreement-regulations-item-text">
          4.1 To the extent that the Charity’s use of the ZAKchat services
          results in access to any Personal Information, the Charity will:<br /><br />
          <div class="zch-legal-agreement-regulations-item-text-sub">
            4.1.1 at all times assist with the responsibilities of ZAKchat, as a
            data controller responsible for determining how the Personal
            Information is processed under the provisions of the Global Privacy
            Laws;<br /><br />
            4.1.2 not do, or cause or permit to be done, anything which may
            result in a breach by ZAKchat of the Global Privacy Laws and comply
            with all reasonable instructions from ZAKchat relating to the
            processing by the Charity of such Personal Information;<br /><br />
            4.1.3 comply with the Local Privacy Laws in respect of the Charity's
            collection, use, disclosure or processing of the Personal
            Information;<br /><br />
            4.1.4 abide by the lawful instructions of all data subjects in
            respect of the Personal Information and not do anything to
            compromise the security of such information;<br /><br />
            4.1.5 not sell, trade or rent Personal Information to third
            parties;<br /><br />
            4.1.6 hold the Personal Information securely and not disclose it to
            anyone other than ZAKchat, as agreed to by the data subject and/or
            as permitted by Global Privacy Laws;<br /><br />
            4.1.7 implement adequate security, technical and organizational
            measures against all unauthorized, unlawful or accidental access,
            processing, use, erasure, loss or destruction of, or damage to,
            Personal Information in accordance with Global Privacy Laws, and
            abide by ZAKchat' reasonable requirements to ensure the security of
            the Personal Information as notified to the Charity from time to
            time;<br /><br />
            4.1.8 use Personal Information appropriately and only for the
            specific purposes as notified to the Charity from time to time,
            including by way of the applicable privacy policy available on
            ZAKchat's Website; and<br /><br />
            4.1.9 not retain any Personal Information for longer than is
            necessary.<br /><br />
          </div>
          4.2 In addition to its obligations under Section 4.1 above, if the
          Charity’s use of the ZAKchat services results in access to any
          Personal Information and the donor has agreed to receive marketing
          communications from the Charity, the Charity will only send marketing
          communications to the donor to the extent that the donor:<br /><br />
          <div class="zch-legal-agreement-regulations-item-text-sub">
            4.2.1 has indicated their preference to receive such communications;
            and<br /><br />
            4.2.2 has not indicated a preference or otherwise asked to stop
            receiving such communications. This includes the donor clicking an
            unsubscribe link or registering with any applicable preference
            service (which the Charity agrees it shall, to the extent it is
            required to do so, check before sending any communications). Where
            use of the ZAKchat services results in access to any Personal
            Information relating to a donor but that donor has not agreed to
            receive marketing communications from the Charity, the Charity shall
            only communicate with that donor in relation to their specific
            donation and shall not (unless separate consent is obtained by the
            Charity) communicate with that person for any other purpose
            (including marketing).<br /><br />
          </div>
          4.3 The Charity shall be liable for and indemnify ZAKchat, its
          successors and assigns against any and all claims, actions,
          liabilities, losses, damages and expenses (including legal expenses)
          incurred by ZAKchat which arise (directly or indirectly) out of or in
          connection with any breach of Sections 4.1 or 4.2 by the Charity.
        </div>
        <div class="zch-legal-agreement-regulations-item-heading">
          5. Intellectual Property Rights and Licence
        </div>
        <div class="zch-legal-agreement-regulations-item-text">
          5.1 All intellectual property rights in any material (including text,
          photographs and other images, trademarks and logos) contained in the
          ZAKchat Services is either owned by ZAKchat or has been licensed to
          ZAKchat by the rights owner(s) so that ZAKchat can use this material
          as part of providing the ZAKchat Services. Nothing in these Terms and
          Conditions shall operate as an assignment of any such intellectual
          property rights.<br /><br />
          5.2 The Charity hereby grants to ZAKchat, its affiliates and its
          partners a nonexclusive, worldwide and royalty-free licence to use its
          name and logo in connection with the performance and promotion of the
          ZAKchat Services.<br /><br />
          5.3 The licence shall terminate automatically on valid termination of
          the Charity's membership in accordance with Section 10 below.<br /><br />
          5.4 The Charity shall be liable for and shall indemnify ZAKchat, its
          successors and assigns against any and all claims, actions,
          liabilities, losses, damages and expenses (including legal expenses)
          incurred by ZAKchat which arise (directly or indirectly) out of or in
          connection with any third party demand, claim or action alleging
          infringement of the third party’s intellectual property rights.
        </div>
        <div class="zch-legal-agreement-regulations-item-heading">6. Fees</div>
        <div class="zch-legal-agreement-regulations-item-text">
          In consideration of using the ZAKchat Services, the Charity shall pay
          any subscription fees set out in this Section 6 to ZAKchat (together,
          the “Fees”).
          <div class="zch-legal-agreement-regulations-item-subheading">
            Subscription Fees
          </div>
          6.1 The subscription fee shall be payable via bank transfer, as a
          lump-sum payment, unless ZAKchat has given express permission
          otherwise. ZAKchat retains the right to suspend the Charity’s use of
          the ZAKchat Services at any time should collection of this amount not
          be successful for any reason or the Charity does not pay any other
          amount due to ZAKchat under these Terms and Conditions for any other
          reason. In this situation, the Charity will only be granted access to
          the ZAKchat Services upon receipt of any outstanding subscription
          fees.<br /><br />
          6.2 Subscription fees will be payable by the Charity within fourteen
          (14) days of receipt of invoice.
        </div>
        <div class="zch-legal-agreement-regulations-item-heading">
          7. LIABILITY
        </div>
        <div class="zch-legal-agreement-regulations-item-text">
          7.1 ZAKCHAT DOES NOT, AND NOTHING IN THESE TERMS AND CONDITIONS SHALL
          ACT TO, EXCLUDE OR LIMIT ZAKCHAT’S OR THE CHARITY’S LIABILITY: (I) FOR
          DEATH OR PERSONAL INJURY RESULTING FROM ITS NEGLIGENCE; (II) FRAUD;
          (III) UNDER ANY INDEMNITY CONTAINED IN THIS AGREEMENT; OR (IV) ANY
          OTHER LIABILITY WHICH MAY NOT BY APPLICABLE LAW BE EXCLUDED OR
          LIMITED.<br /><br />
          7.2 The Charity agrees that the ZAKCHAT services ARE provided on an
          "as is" and "as available" basis and that its use of the ZAKCHAT
          services is at the Charity's sole risk. ZAKCHAT does not guarantee
          continuous, uninterrupted, error or virus free or secure access to our
          Services and operation of the ZAKCHAT services may be interfered with
          by numerous factors outside of ZAKCHAT’s control. On that basis,
          except as expressly set out in these Terms and Conditions, ZAKCHAT
          does not enter into conditions, warranties or other terms in relation
          to the ZAKCHAT services, and they are excluded to the fullest extent
          permissible by law.<br /><br />
          7.3 Subject to Section 7.1, in no event shall ZAKCHAT be liable
          (whether for breach of contract, negligence or for any other reason)
          for any loss or damage which the Charity may claim to have suffered by
          reason of its (or any donor, fundraiser or other user) accessing and
          use of (or inability to access and use) the ZAKCHAT services OR ANY
          PART OF THEM, including (but not limited to) loss of profits,
          exemplary or special damages, loss of sales, LOSS OF DONATIONS, LOSS
          OF GIFT AID, loss of revenue, loss of goodwill, loss of any software
          or data, loss of bargain, loss of opportunity, loss of use of computer
          equipment, software or data, loss of or waste of management or other
          staff time, or for any indirect, incidental consequential or special
          loss, however arising.<br /><br />
          7.4 ZAKCHAT may change the format and content of the ZAKCHAT services
          from time to time. IN PARTICULAR, The Charity should refresh its
          browser each time it visits the Website to ensure that the Charity
          downloads the most up to date version of the Website, including the
          latest version of these Terms and Conditions.<br /><br />
          7.5 Subject to the other terms of this section 7, the total aggregate
          liability of ZAKCHAT arising under or in connection with the Charity
          (or any donor, or other user) accessing and using the ZAKCHAT services
          and inability to access or use THE ZAKCHAT SERVICES shall be limited
          to the total fees paid under these Terms and Conditions by the Charity
          during the preceding 12 months period ending on the date the
          circumstances giving rise to the liability arose.<br /><br />
          7.6 Some countries do not allow the exclusion of implied warranties or
          limitation of liability for incidental or consequential damages which
          means that some of the above limitations may not apply to Charities
          BASED IN THOSE COUNTRIES. In these countries, ZAKCHAT's liability will
          be limited to the greatest extent permitted by law.
        </div>
        <div class="zch-legal-agreement-regulations-item-heading">
          8. Insurance
        </div>
        <div class="zch-legal-agreement-regulations-item-text">
          Each party must at all times maintain such adequate insurances as are
          required by local applicable laws or as are customary for the parties
          to an agreement of the nature of these Terms and Conditions to
          maintain in the local market place.
        </div>
        <div class="zch-legal-agreement-regulations-item-heading">
          9. Confidential Information
        </div>
        <div class="zch-legal-agreement-regulations-item-text">
          During the term of the Charity's membership and for a period of seven
          (7) years thereafter, the parties shall treat as strictly confidential
          all information about the other which has been acquired as a result of
          the use of ZAKchat's Services and which is not in the public domain.
          No party shall use or disclose to any third party such information
          belonging to the other party without that party's prior written
          consent, except where required to do so by applicable local law or
          regulatory or governmental body. This Section 9 shall survive
          termination of these Terms and Conditions.<br /><br />
          The applicable local laws in the United Kingdom, which may require
          disclosure of otherwise confidential information, include, without
          limitation, the Freedom of Information Act 2000 and the Environmental
          Information Regulations 2004.
        </div>
        <div class="zch-legal-agreement-regulations-item-heading">
          10. Termination
        </div>
        <div class="zch-legal-agreement-regulations-item-text">
          10.1 In accordance with Section 1 above, notice of cancellation should
          be received no later than 6 months prior to the Ramadan of that year.
          All notices from a Charity must include the Charity’s full name and
          registered charity number. Such notice shall be deemed to have been
          received within five (5) business days if posted (att: finance
          department) and within twentyfour (24) hours if emailed
          (contact@ZAKchat.com). Late cancellation notices may not result in a
          refund of the subscription fees. Please note that cancelling a direct
          debit that has been set up to pay the subscription fee described in
          Section 6 is not sufficient to cancel a Charity membership; so that
          ZAKchat can process a cancellation request accurately and on time,
          post or email notice must also be provided to ZAKchat in accordance
          with this Section 10.1.<br /><br />
          10.2 In addition to the rights of each party under Sections 1 and 10.1
          above, each party will have the right to terminate the use (and
          provision) of the ZAKchat Services with immediate effect by notice in
          writing when, and at any time after, the other party commits an act of
          default (as defined below) or commits any act or threatens to do any
          act the direct result of which is to damage or is likely to damage the
          reputation of the other party. An act of default by either party shall
          occur if:<br /><br />
          <div class="zch-legal-agreement-regulations-item-text-sub">
            10.2.1 the party is wound up or a liquidator or examiner is
            appointed;<br /><br />
            10.2.2 an administration order is made in relation to the party or a
            receiver or an administrative receiver is appointed over or an
            encumbrancer takes possession of or sells all of the party's
            assets;<br /><br />
            10.2.3 the party makes an arrangement or composition with its
            creditors generally;<br /><br />
            10.2.4 the party ceases or threatens to cease to carry on its
            business;<br /><br />
            10.2.5 any event analogous to the events listed in Sections 10.2.1 -
            10.2.4 (inclusive) occurs in any territory in relation to the party
            or any step towards any such event or analogous event is taken by
            any person and not promptly dismissed or reversed.<br /><br />
          </div>

          10.3 In addition to its rights under Sections 1, 10.1 and 10.2 above,
          in the event that the Charity materially breaches any of these Terms
          and Conditions or if ZAKchat reasonably considers that any of the
          events listed in Sections 3.5.1, 3.5.2, 3.5.3 or 3.5.4 apply (or are
          likely to apply), ZAKchat may, at its sole discretion, without notice
          and with no liability to the Charity do one or both of the following:

          <div class="zch-legal-agreement-regulations-item-text-sub">
            10.3.1 suspend or terminate the Charity's access to the ZAKchat
            Services;<br /><br />
            10.3.2 suspend any payments due to the Charity under Section 2.3.<br /><br />
          </div>
          10.4 To the maximum extent permitted by applicable law ZAKchat, in any
          event, reserves the right to withdraw the ZAKchat Services (or any
          part of them) from public access at any time, at its complete
          discretion.
        </div>
        <div class="zch-legal-agreement-regulations-item-heading">
          11. Suspension of ZAKchat Services
        </div>
        <div class="zch-legal-agreement-regulations-item-text">
          ZAKchat may, in its sole discretion, without liability to the Charity,
          suspend the operation of any of the ZAKchat Services in full or in
          part at any time, for example but without limitation for repair or
          maintenance work or in order to update or upgrade the contents or
          functionality of the ZAKchat Services from time to time.
        </div>
        <div class="zch-legal-agreement-regulations-item-heading">
          12. Notices and Entire Agreement
        </div>
        <div class="zch-legal-agreement-regulations-item-text">
          12.1 Notices referred to above should be made in writing and sent by
          registered post to the addresses given in the applicable country
          specific terms or, in the case of notices to be sent by or to ZAKchat,
          by email to contact@ZAKchat.com.<br /><br />
          12.2 These Terms and Conditions represent the entire agreement between
          ZAKchat and the Charity and supersede and replace any other
          representations made orally or in writing.
        </div>
        <div class="zch-legal-agreement-regulations-item-heading">
          13. Third Party Rights
        </div>
        <div class="zch-legal-agreement-regulations-item-text">
          A person who is not a party to these Terms and Conditions has no right
          to enforce any term of these Terms and Conditions.
        </div>
        <div class="zch-legal-agreement-regulations-item-heading">
          14. Grievances from employees, complaints handling process and dispute
          resolution
        </div>
        <div class="zch-legal-agreement-regulations-item-subheading">
          Grievances from employees
        </div>
        <div class="zch-legal-agreement-regulations-item-text">
          14.1 Any grievances by employees of either party will be dealt with by
          that party.
        </div>
        <div class="zch-legal-agreement-regulations-item-subheading">
          Complaint handling procedure
        </div>
        <div class="zch-legal-agreement-regulations-item-text">
          14.2 ZAKchat will promptly respond to any complaints from donors
          concerning the ZAKchat Services.<br /><br />
          14.3 ZAKchat will maintain records relating to complaints it receives.
        </div>
        <div class="zch-legal-agreement-regulations-item-subheading">
          Dispute resolution
        </div>
        <div class="zch-legal-agreement-regulations-item-text">
          14.4 If a dispute arises under these Terms and Conditions or
          concerning its subject matter, either party may at any time give
          written notice to the other requesting that a meeting take place to
          seek to resolve the dispute. The nominated senior representatives of
          both parties must meet within five business days of the notice and try
          to resolve the dispute in good faith. If such a meeting does not take
          place or if five business days after the meeting the dispute remains
          unresolved, either party may pursue its rights at law.<br /><br />
          14.5 Despite the existence of a dispute, each party must continue to
          perform its obligations under these Terms and Conditions.<br /><br />
          14.6 Sections 14.4 and 14.5 do not restrict or limit the right of
          either party to obtain interim, protective or interlocutory relief, or
          to immediately terminate these Terms and Conditions where these Terms
          and Conditions provide such a right.
        </div>
        <div class="zch-legal-agreement-regulations-item-heading">
          15. Governing Law
        </div>
        <div class="zch-legal-agreement-regulations-item-text">
          These Terms and Conditions and any contractual or non-contractual
          claim arising out of or in connection with a Charity’s use of the
          ZAKchat Services are governed by the law. The governing law for the
          purpose of Section 15 is the law of England, and the English courts
          have exclusive jurisdiction.
        </div>
        <div class="zch-legal-agreement-regulations-item-heading">
          Additional terms for Charities in the United Kingdom
        </div>
        <div class="zch-legal-agreement-regulations-item-subheading">
          Gift Aid
        </div>
        <div class="zch-legal-agreement-regulations-item-text">
          1.1 ZAKchat will offer all donors who are UK taxpayers the option of
          making donations in a manner that makes them eligible for Gift Aid
          reclaims. 1.2 ZAKchat will make available to Charity all data
          necessary to process the Gift Aid of their donors where the donor has
          given the appropriate permissions.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from "../layouts/Navigation";

export default {
  name: "LegalAgreement",
  components: {
    Navigation,
  },
};
</script>

<style lang="scss" scoped>
.zch-legal-agreement {
  min-height: 100vh;
  width: 100%;
  padding: 24px;
  background: url(../assets/background-logo.png) no-repeat,
    radial-gradient(circle at 50% 50%, #fbfffe, #f6fffc 151%) no-repeat;
  background-size: contain;
  background-position-x: right;
  margin-bottom: 50px;

  &-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 16.75%;
  }

  &-heading {
    margin-top: 50px;
    font-family: Muli, sans-serif;
    text-align: center;
    margin-bottom: 91px;

    & > img {
      max-height: 70px;
    }

    &-effective-date {
      font-family: Muli, sans-serif;
      font-size: 16px;
      font-weight: bold;
      color: $color-montecarlo;
      text-transform: uppercase;
    }

    &-notice {
      font-size: 44px;
      font-weight: 800;
      text-align: center;
      color: #2b2b2b;
    }
  }

  &-regulations {
    &-item {
      &-heading {
        font-family: Muli, sans-serif;
        font-size: 20px;
        font-weight: 800;
        color: #2b2b2b;
        margin-left: -33px;
        padding-top: 40px;
      }

      &-subheading {
        font-family: Muli, sans-serif;
        font-size: 18px;
        font-weight: 700;
        color: #2b2b2b;
        margin-left: -20px;
        padding-top: 20px;
      }

      &-text {
        padding-top: 13px;
        font-family: Muli, sans-serif;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.94;
        letter-spacing: normal;
        color: #6c6c6c;

        &-sub {
          margin-left: 10px;
          padding-top: 10px;
        }
      }
    }
  }
}
</style>
